
import { Platform } from '@ionic/angular';
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { Router } from '@angular/router';
import { Storage } from '@ionic/storage'
import { WorkoutModel } from 'src/model/workout.model';
const TOKEN_KEY = 'auth-token';

@Injectable({
    providedIn: 'root'
})
export class AuthService {
    authenticationState = new BehaviorSubject(false);

    constructor(private storage: Storage,private router: Router, private plt: Platform) {
        this.plt.ready().then(() => {
            this.checkToken();
        });
    }

    checkToken() {
        this.storage.get(TOKEN_KEY).then(res => {
            if (res) {
                this.authenticationState.next(true);
            }
        });
    }

    login(token) {
        return this.storage.set(TOKEN_KEY, 'Bearer ' + token).then(() => {
            this.authenticationState.next(true);
            this.router.navigate(['/schedule']);
        });
    }

    logout() {
        return this.storage.remove(TOKEN_KEY).then(() => {
            this.authenticationState.next(false);
            this.router.navigate(['/']);
        });
    }

    isAuthenticated() {
        this.checkToken();
        return this.authenticationState.value;
    }

    getToken() {
        return this.storage.get(TOKEN_KEY);
    }

    saveInCache(workout){
        return this.storage.set("CACHE_WORKOUT", workout).then(()=>{
            return true;
        });
    }

}