import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AuthService } from './auth.service';

/**
 * Api is a generic REST Api handler. Set your API url first.
 */

@Injectable()
export class Api {

  // public static API_URLBASE: string = 'http://belfort-ssl.ddns.net/';
     public static API_URLBASE: string = 'https://workout.belfortlifestyle.com';
  // public static API_URLBASE: string = 'https://workout.belfortlifestyle.com';
  // public static API_URLBASE: string = 'http://localhost:8080';
  public static API_URL: string = Api.API_URLBASE + '/api';
  private userToken: string;

  constructor(public http: HttpClient, private authService: AuthService) {
    this.getToken();
  }

  getToken() {
    this.authService.getToken().then((val) => {
      this.userToken = val;
    });
  }

  get(endpoint: string, params?: any, reqOpts?: any) {
    const headers = new HttpHeaders().set('Authorization', this.userToken);
    return this.http.get(Api.API_URL + '/' + endpoint, { headers });
  }

  post(endpoint: string, body: any, reqOpts?: any) {
    const headers = new HttpHeaders().set('Authorization', 'Bearer ' + this.userToken);

    return this.http.post(Api.API_URL + '/' + endpoint, body, { headers });
  }

  put(endpoint: string, body: any, reqOpts?: any) {
    const headers = new HttpHeaders().set('Authorization', 'Bearer ' + this.userToken);

    return this.http.put(Api.API_URL + '/' + endpoint, body, { headers });
  }

  delete(endpoint: string, reqOpts?: any) {
    const headers = new HttpHeaders().set('Authorization', 'Bearer ' + this.userToken);
    return this.http.delete(Api.API_URL + '/' + endpoint, { headers });
  }

  patch(endpoint: string, body: any, reqOpts?: any) {
    const headers = new HttpHeaders().set('Authorization', 'Bearer ' + this.userToken);
    return this.http.put(Api.API_URL + '/' + endpoint, body, { headers });
  }
}


