import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  { path: 'schedule', loadChildren: () => import('./schedule/schedule.module').then(m => m.ScheduleModule) },
  { path: '', loadChildren: () => import('./login/login.module').then(m => m.LoginModule) },
  { path: 'day-list', loadChildren: () => import('./day-list/day-list.module').then(m => m.DayListModule) },
  { path: 'bag-playlist', loadChildren: () => import('./bag-playlist/bag-playlist.module').then(m => m.BagPlaylistModule) },
  { path: 'floor-playlist', loadChildren: () => import('./floor-playlist/floor-playlist.module').then(m => m.FloorPlaylistModule) },
];
@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
